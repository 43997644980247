import React, { useState, useEffect } from 'react';
import './App.css';

const API_URL = '/proxy2'; // Cloudflare Pages proxy URL

function App() {
  const [clients, setClients] = useState([]); // All clients
  const [displayedClients, setDisplayedClients] = useState([]); // Clients to display after filtering and pagination
  const [trades, setTrades] = useState([]); // All trades for the selected client
  const [paginatedTrades, setPaginatedTrades] = useState([]); // Paginated trades to display
  const [loading, setLoading] = useState(true);
  const [clientPagination, setClientPagination] = useState({ limit: 5, offset: 0 });
  const [tradePagination, setTradePagination] = useState({ limit: 5, offset: 0 }); // Pagination for trades
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [clientFilter, setClientFilter] = useState(''); // Filter for client names
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  // Sorting state for trades
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

  // Fetch clients data
  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);

      const body = {
        path: '/api/clients',
        queryParams: { limit: 1000, offset: 0 }, // Fetch all clients for now
        requestType: 'GET',
      };

      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setClients(data.items || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching clients data:', error);
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  // Handle client selection
  const handleClientClick = (clientId) => {
    setSelectedClientId(clientId);
    setTradePagination({ limit: 5, offset: 0 }); // Reset trades pagination when new client is selected
    setTrades([]); // Clear previous trades
    setDateFrom(''); // Reset date filters when client is changed
    setDateTo('');
  };

  // Handle client filter input change
  const handleClientFilterChange = (e) => {
    setClientFilter(e.target.value);
    setClientPagination({ limit: 5, offset: 0 }); // Reset to first page when filter changes
  };

  // Filter and paginate clients
  useEffect(() => {
    const filteredClients = clients.filter((client) =>
      client.company_name.toLowerCase().includes(clientFilter.toLowerCase())
    );

    const paginatedClients = filteredClients.slice(
      clientPagination.offset,
      clientPagination.offset + clientPagination.limit
    );

    setDisplayedClients(paginatedClients);
  }, [clients, clientFilter, clientPagination]);

  // Fetch trades for the selected client
  useEffect(() => {
    if (!selectedClientId) return;

    const fetchTrades = async () => {
      setLoading(true);

      const body = {
        path: `/api/clients/${selectedClientId}/trades`,
        queryParams: { limit: 1000, offset: 0 },
        requestType: 'GET',
      };

      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setTrades(data || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching trades data:', error);
        setLoading(false);
      }
    };

    fetchTrades();
  }, [selectedClientId]);

  // Handle trades pagination
  useEffect(() => {
    const filteredTrades = trades.filter((trade) => {
      const tradeDate = new Date(trade.date);
      const fromDate = dateFrom ? new Date(dateFrom) : null;
      const toDate = dateTo ? new Date(dateTo) : null;

      return (
        (!fromDate || tradeDate >= fromDate) &&
        (!toDate || tradeDate <= toDate)
      );
    });

    const sortedTrades = [...filteredTrades].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      if (sortOrder === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    const paginatedTradesData = sortedTrades.slice(
      tradePagination.offset,
      tradePagination.offset + tradePagination.limit
    );
    setPaginatedTrades(paginatedTradesData);
  }, [trades, tradePagination, dateFrom, dateTo, sortOrder]);

  // Reset date filters
  const resetDateFilters = () => {
    setDateFrom('');
    setDateTo('');
  };

  // Handle sorting toggle for date column
  const handleSortDate = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  // Arrow indicator for sorting
  const sortArrow = sortOrder === 'asc' ? '↑' : '↓';

  // Find the selected client's name
  const selectedClient = clients.find(client => client.id === selectedClientId);
  const clientName = selectedClient ? selectedClient.company_name : '';

  return (
    <div className="App">
      <h1>Test Dashboard (with fictitious data)</h1>
      <div>Disclaimer: Data shown is fictitious and does not represent real world data.</div>

      <div>
        <h2>Filter Clients</h2>
        <input
          type="text"
          value={clientFilter}
          onChange={handleClientFilterChange}
          placeholder="Search by company name"
        />
      </div>

      <div>
        <h2>Clients</h2>
        {loading && <p>Loading clients...</p>}
        <table>
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Location</th>
              <th>Total AUM ($B)</th>
              <th>Year Established</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {displayedClients.map((client) => (
              <tr key={client.id}>
                <td>{client.company_name}</td>
                <td>{client.location}</td>
                <td>{client.total_aum}</td>
                <td>{client.year_established}</td>
                <td>
                  <button onClick={() => handleClientClick(client.id)}>View Trades</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            onClick={() =>
              setClientPagination((prev) => ({
                ...prev,
                offset: Math.max(prev.offset - prev.limit, 0),
              }))
            }
            disabled={clientPagination.offset === 0}
          >
            Previous
          </button>
          <button
            onClick={() =>
              setClientPagination((prev) => ({
                ...prev,
                offset: prev.offset + prev.limit,
              }))
            }
            disabled={displayedClients.length < clientPagination.limit}
          >
            Next
          </button>
        </div>
      </div>

      {selectedClientId && (
        <div className="date-picker-container">
          <div className="date-labels">
            <label htmlFor="dateFrom">From Date:</label>
            <input
              id="dateFrom"
              type="date"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
            />
            <label htmlFor="dateTo">To Date:</label>
            <input
              id="dateTo"
              type="date"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
            />
            <button className="reset-btn" onClick={resetDateFilters}>
              Reset Dates
            </button>
          </div>
        </div>
      )}

      {selectedClientId && (
        <div>
          <h2>Trades for Client: {clientName}</h2> {/* Displaying client name */}
          {loading && <p>Loading trades...</p>}
          <div className="trade-table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <button onClick={handleSortDate}>
                      Date {sortArrow}
                    </button>
                  </th>
                  <th>Side</th>
                  <th>Ticker</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Commission Amount</th>
                </tr>
              </thead>
              <tbody>
                {paginatedTrades.map((trade) => (
                  <tr key={trade.id}>
                    <td>{trade.date}</td>
                    <td>{trade.side}</td>
                    <td>{trade.ticker}</td>
                    <td>{trade.quantity}</td>
                    <td>{trade.price}</td>
                    <td>{trade.commission_amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="pagination">
            <button
              onClick={() =>
                setTradePagination((prev) => ({
                  ...prev,
                  offset: Math.max(prev.offset - prev.limit, 0),
                }))
              }
              disabled={tradePagination.offset === 0}
            >
              Previous
            </button>
            <button
              onClick={() =>
                setTradePagination((prev) => ({
                  ...prev,
                  offset: prev.offset + prev.limit,
                }))
              }
              disabled={paginatedTrades.length < tradePagination.limit}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
